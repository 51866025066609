const data = [
    {
        id: 1,
        title: "Hybrid App",
        image: "img/project/project-1.jpg",
        category: ["mobile-app", "web-app", "ui"],
        details: "Vocifiy - AI for Content Creators",
    },
    {
        id: 2,
        title: "Mobile App",
        image: "img/project/project-2.jpg",
        category: ["mobile-app", "web-app", "back-end"],
        details: "Grammyo - Social Networking for Film Industry",
    },
    {
        id: 3,
        title: "App",
        image: "img/project/project-3.jpg",
        category: ["mobile-app", "back-end", "ui"],
        details: "Brainzana - AI based translator",
    },
    {
        id: 4,
        title: "Mobile App",
        image: "img/project/project-4.jpg",
        category: ["mobile-app"],
        details: "Privy Manager - Offline password manager",
    },
    {
        id: 5,
        title: "Graphic",
        image: "img/project/project-5.jpg",
        category: ["mobile-app", "back-end", "ui"],
        details: "Multi-vendor E-commerce",
    },
    {
        id: 6,
        title: "3D Design",
        image: "img/project/project-6.jpg",
        category: ["developing"],
        details: "Low Poly Base Mesh",
    },
];

export default data;