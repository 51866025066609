import React from "react";
import RelatedProject from "./RelatedProject";
import { useParams } from "react-router-dom";

let Projects = {};
let Sidebars = {};

Projects["Project-1"] = require("./Projects/Project-1").default;
Projects["Project-2"] = require("./Projects/Project-1").default;
Projects["Project-3"] = require("./Projects/Project-1").default;

Sidebars["Sidebar-1"] = require("./Sidebars/Sidebar-1").default;
Sidebars["Sidebar-2"] = require("./Sidebars/Sidebar-1").default;
Sidebars["Sidebar-3"] = require("./Sidebars/Sidebar-1").default;

function ProjectDetailsWrap() {
  const { id } = useParams();
  const ProjectWidget = Projects[`Project-${id}`];
  const SidebarWidget = Sidebars[`Sidebar-${id}`];

  return (
    <div>
      <div className="project-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ProjectWidget />
            </div>
            <div className="col-lg-4">
              <SidebarWidget />
            </div>
            <RelatedProject />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailsWrap;
