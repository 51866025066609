import React, { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import data from "../../../data/projects-data";

function ProjectCardFilter() {
  const [items, setItem] = useState(data);
 
  const filterItem = (catagoryitem) => {
    const updateItem = data.filter((curentelement) => {
      return curentelement.category.includes(catagoryitem);
    });

    setItem(updateItem);
  };

  const initialState = { statementActive: "" };
  function reducer(state, action) {
    switch (action.type) {
      case "dataAll":
        setItem(data);
        return { statementActive: "dataAll" };
      case "Mobile App":
        filterItem("mobile-app");
        return { statementActive: "mobile-app" };
      case "Web App":
        filterItem("web-app");
        return { statementActive: "web-app" };
      case "Backend":
        filterItem("back-end");
        return { statementActive: "back-end" };
      case "UI/UX":
        filterItem("ui");
        return { statementActive: "ui" };
      case "Template":
        filterItem("template");
        return { statementActive: "template" };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="isotope-menu">
            <li
              onClick={() => dispatch({ type: "dataAll" })}
              className={state.statementActive === "dataAll" ? "active" : ""}
            >
              All
            </li>
            <li
              onClick={() => dispatch({ type: "Mobile App" })}
              data-filter=".mobile-app"
              className={state.statementActive === "Mobile App" ? "active" : ""}
            >
              Mobile App
            </li>
            <li
              onClick={() => dispatch({ type: "Web App" })}
              data-filter=".web-app"
              className={state.statementActive === "Web App" ? "active" : ""}
            >
              Web App
            </li>
            <li
              onClick={() => dispatch({ type: "Backend" })}
              data-filter=".back-end"
              className={state.statementActive === "Backend" ? "active" : ""}
            >
              Backend
            </li>
            <li
              onClick={() => dispatch({ type: "UI/UX" })}
              data-filter=".ui"
              className={state.statementActive === "UI/UX" ? "active" : ""}
            >
              UI/UX
            </li>
            <li
              onClick={() => dispatch({ type: "Template" })}
              data-filter=".template"
              className={state.statementActive === "Template" ? "active" : ""}
            >
              Template
            </li>
          </ul>
        </div>
      </div>
      <div className="row g-4 project-items d-flex">
        {items.map((element) => {
          const { title, image, id, details } = element;
          return (
            <div key={id} className="col-md-6 col-lg-4 single-item">
              <div className="item-img">
                <Link to={`${process.env.PUBLIC_URL}/project`}>
                  <img src={image} alt="images" />
                </Link>
              </div>
              <div className="item-inner-cnt">
                <span>{title}</span>
                <h4>{details}</h4>
                <div className="view-btn">
                  <Link to={`${process.env.PUBLIC_URL}/project-details/${id}`}>
                    view details
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProjectCardFilter;
